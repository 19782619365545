<template>
  <div style="display: flex; flex-direction: column">
    <select2
      :disabled="readOnly"
      v-model="preference"
      :placeholder="$t('consentPreferenceSelectCategory')"
    >
      <option
        :key="0"
        :title="$t('consentPreferenceIgnoreSubscriptionOption')"
        value="0"
      >
        {{ $t('consentPreferenceIgnoreSubscriptionOption') }}
      </option>
      <optgroup
        v-for="(item, index) in availablePreferences"
        :key="`g-${index}`"
        :label="`${$t(consentPreferenceType[item.type])}`"
      >
        <option
          v-for="(option, oIndex) in item.options"
          :key="`o-${oIndex}`"
          :value="option.value"
          :title="option.titleSelected"
        >
          {{ option.title }}
        </option>
      </optgroup>
    </select2>
  </div>
</template>

<script>
import { ConsentChannels } from '@/enums/ConsentChannelEnum'
import { consentPreferenceType } from '@/enums/ConsentPreferenceEnum'

export default {
  name: 'SelectConsentPreference',

  data() {
    return {
      consentPreferenceType,
      ConsentChannels,
      maxNameCharacters: 50
    }
  },

  props: {
    value: {
      type: String,
      default: null
    },
    preferences: {
      type: Array,
      default: () => []
    },
    channel: {
      type: String,
      default: () => undefined
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    preference: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue)
      },
      deep: true
    },

    systemChannels() {
      return Object.values(ConsentChannels)
    },

    availablePreferences() {
      return this.preferences
        .filter((item) => {
          if (this.channel) {
            return item.channelList && item.channelList[this.channel] === 1
          }

          return this.systemChannels.some((channel) => {
            return item.channelList && item.channelList[channel] === 1
          })
        })
        .reduce(
          (acc, item) => {
            acc[item.type].options.push({
              title: item.name,
              titleSelected: `${item.name} (${this.$t(
                consentPreferenceType[item.type]
              )})`,
              value: item.name
            })

            return acc
          },
          [
            {
              type: 0,
              options: []
            },
            {
              type: 1,
              options: []
            }
          ]
        )
        .filter((item) => item.options.length)
    }
  }
}
</script>
