var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin":"20px"}},[_c('button',{staticClass:"btn btn-default btn-block",attrs:{"type":"button"},on:{"click":_vm.openEditor}},[_vm._v("\n    "+_vm._s(!_vm.isAvailable ? _vm.$t('previewBtn') : _vm.$t('editBtn'))+"\n  ")]),_vm._v(" "),(_vm.isExclusionEnabled)?_c('div',{staticClass:"panel panel-default"},[_c('div',{staticClass:"panel-body"},[_c('div',{staticClass:"sms-prop-name"},[_vm._v("\n        "+_vm._s(_vm.$t('smsSmallToolboxExclusionCategory'))+":\n      ")]),_vm._v(" "),(
          _vm.action.config.exclusion && _vm.action.config.exclusion.check === false
        )?_c('div',{staticClass:"email-prop-value"},[_vm._v("\n        "+_vm._s(_vm.$t('smsSmallToolboxExclusionCategoryNotEnabled'))+"\n      ")]):_vm._e(),_vm._v(" "),(
          (_vm.action.config.exclusion && _vm.action.config.exclusion.check) ||
          _vm.displayExclusionError
        )?_c('div',{staticClass:"sms-prop-value",class:{
          'invalid-text':
            _vm.displayExclusionError ||
            _vm.$v.action.config.exclusion.category.$invalid
        }},[_vm._v("\n        "+_vm._s(_vm.exclusionCategoryName)+"\n        "),(
            _vm.displayExclusionError ||
            !_vm.$v.action.config.exclusion.category.required
          )?[_vm._v("\n          "+_vm._s(_vm.$t('smsSmallToolboxMissingExclusionCategory'))+"\n        ")]:_vm._e()],2):_vm._e()])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"panel panel-default"},[_c('div',{staticClass:"panel-body"},[_c('div',{staticClass:"sms-prop-name"},[_vm._v("\n        "+_vm._s(_vm.$t('smsSmallToolboxIgnoreSubscription'))+":\n      ")]),_vm._v(" "),_c('div',{staticClass:"sms-prop-value"},[_vm._v("\n        "+_vm._s(_vm.ignoreSubscriptionMap(_vm.action.config.ignore_subscription))+"\n      ")]),_vm._v(" "),(_vm.isConsentPreferenceEnabled)?_c('div',[_c('div',{staticClass:"sms-prop-name"},[_vm._v("\n          "+_vm._s(_vm.$t('smsSmallToolboxConsentPreference'))+":\n        ")]),_vm._v(" "),_c('div',{staticClass:"sms-prop-value"},[_vm._v("\n          "+_vm._s(_vm.consentPreferenceMap(_vm.action.config.consent_preference.preference))+"\n        ")])]):_vm._e()])]),_vm._v(" "),_c('label',[_c('icon',{attrs:{"glyph":"sms"}}),_vm._v("\n    "+_vm._s(_vm.$t('smsSmallToolboxSmsPreviewLabel'))+":\n  ")],1),_vm._v(" "),_c('div',{staticStyle:{"display":"flex","justify-content":"center","background":"#083146","padding":"30px","border-radius":"4px"}},[_c('device-sms-message-preview',{attrs:{"fromName":_vm.fromName,"text":_vm.text,"unsubscribe-url":_vm.generateUnsubscribeUrl,"optOutMessage":_vm.getOptOutMessage,"shorten-url":_vm.getShortenedLink}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }