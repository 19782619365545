<script>
import Action from './AbstractAction'
import XpApi from "@/libs/XpApi";

export default {
  extends: Action,
  inject: ['workflowApi'],
  data() {
    return {
      icon: 'volume-high'
    }
  },
  created() {
    if (this.action.promotion_id && !this.readOnly) {
      let promotion = this.$store.state.project.workflowPromotions.find(
        (promotion) => promotion.id == this.action.promotion_id
      )
      if (!promotion) {
        XpApi.get(`projects/${this.$route.params.projectId}/promotions`, {
          params: { id: this.action.promotion_id }
        }).then(({ data }) => {
          if (data.length === 0) {
            this.workflowApi.updateAction(this.action.id, { promotion_id: "" })
          }
        })
      }
    }
  }
}
</script>
