<template>
  <tabs nav-class="nav-side" starting-tab="setup" :full-height="true">
    <tab
      :position="1"
      icon="pencil"
      id="setup"
      class="setup-tab form-horizontal"
    >
      <div class="form-group">
        <div class="col-md-12">
          <label>Push to:</label>
          <div>
            <toggle-button
              color="#1d87c8"
              :value="!!config.ios"
              :sync="true"
              @change="toggle('ios')"
              :disabled="!iosPushEnabled"
            />
            <span @click="toggle('ios')">
              <icon glyph="apple" />
              {{ $t('pushToolboxLargeIosLabel') }}
            </span>
          </div>
          <div>
            <toggle-button
              color="#1d87c8"
              :value="!!config.android"
              :sync="true"
              @change="toggle('android')"
              :disabled="!androidPushEnabled"
            />
            <span @click="toggle('android')">
              <icon glyph="android" />
              {{ $t('pushToolboxLargeAndroidLabel') }}
            </span>
          </div>
          <div>
            <toggle-button
              color="#1d87c8"
              :value="!!config.web"
              :sync="true"
              @change="toggle('web')"
              :disabled="!webPushEnabled"
            />
            <span @click="toggle('web')">
              <icon glyph="web" />
              {{ $t('pushToolboxLargeWebLabel') }}
            </span>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="col-md-12">
          <label>{{ $t('pushToolboxLargeDefaultLanguageLabel') }}:</label>
          <language-selector
            :value="config.defaultLang"
            @input="setDefaultLang"
          />
        </div>
      </div>

      <div class="form-group">
        <div class="col-md-12">
          <label>{{ $t('pushToolboxLargeAdditionalLanguageLabel') }}:</label>
          <multi-language-selector
            style="width: 50%"
            :value="filteredLanguages"
            @input="setLanguages"
          />
        </div>
      </div>

      <national-exclusion-check-settings
        v-if="
          $store.getters.isFeatureEnabled('nationalExclusionCheckBetsson') ||
          $store.getters.isFeatureEnabled('nationalExclusionCheckOasis')
        "
        v-model="config.exclusion"
        :disabled="readOnly"
      />

      <div class="form-group" v-if="config.hasOwnProperty('promotion_id')">
        <div class="col-md-12">
          <label>{{ $t('pushToolboxLargePromotionLabel') }}:</label>
          <div>
            <el-autocomplete
                clearable
                :debounce="500"
                :placeholder="placeholder"
                :disabled="readOnly || !loaded"
                v-model="selectedPromotionName"
                :fetch-suggestions="querySearchPromotions"
                @select="updatePromotion"
                @clear="updatePromotion({})"
            />
          </div>
        </div>
      </div>

      <div
        class="form-group"
        v-if="
          config.hasOwnProperty('promotion_amount_required') &&
          config.promotion_amount_required
        "
      >
        <div class="col-md-12">
          <label class="control-label">
            {{ $t('pushToolboxLargePromotionAmountLabel') }}:
          </label>
          <input
            class="form-control"
            type="number"
            v-model="config.promotion_amount"
            :disabled="readOnly"
          />
        </div>
      </div>

      <div
        class="form-group"
        v-if="
          config.hasOwnProperty('promotion_duration_required') &&
          config.promotion_duration_required
        "
      >
        <div class="col-md-12">
          <label class="control-label">
            {{ $t('pushToolboxLargePromotionDurationLabel') }}:
          </label>
          <input
            class="form-control"
            type="number"
            v-model="config.promotion_duration"
            :disabled="readOnly"
          />
        </div>
      </div>

      <div class="form-group" v-if="encryptionEnabled">
        <div class="col-md-12">
          <label>{{ $t('pushToolboxLargeEncryptionLabel') }}:</label>
          <select-encryption
            v-model="config.encryption"
            :disabled="readOnly || (!iosEnabled && !androidEnabled)"
          />
        </div>
      </div>
      <div class="form-group" v-if="iosEnabled || androidEnabled || webEnabled">
        <div class="col-md-12">
          <label>{{ $t('pushToolboxLargeOfflineUsersLabel') }}:</label>
          <select-retry-type
            v-model="config.retry_type"
            :disabled="
              readOnly || (!iosEnabled && !androidEnabled && !webEnabled)
            "
          />
          <retry-for
            v-if="
              config.retry_type === 1 &&
              (iosEnabled || androidEnabled || webEnabled)
            "
            :retry_for="config.retry_for"
            @retry_for="config.retry_for = $event"
            :retry_for_period="config.retry_for_period"
            @retry_for_period="config.retry_for_period = $event"
            :disabled="
              readOnly || (!iosEnabled && !androidEnabled && !webEnabled)
            "
          />
          <retry-until
            v-if="
              config.retry_type === 2 &&
              (iosEnabled || androidEnabled || webEnabled)
            "
            v-model="config.retry_until"
            :disabled="
              readOnly || (!iosEnabled && !androidEnabled && !webEnabled)
            "
          />
        </div>
      </div>
    </tab>
    <tab
      :position="2"
      icon="apple"
      id="ios"
      style="padding: 20px"
      :disabled="!iosEnabled || !iosPushEnabled"
    >
      <div class="flexbox">
        <div class="col-md-6">
          <h3>{{ $t('pushToolboxLargeIosLabel') }}</h3>
          <device-push
            v-if="iosEnabled"
            v-model="config.ios"
            :profile-attributes="profileAttributes"
            :default-lang="config.defaultLang"
            :languages="config.languages"
            :ios="true"
            :with-voucher-codes="voucherCodesEnabled('ios')"
            @uploadPicture="onFileSelected('ios', 'pictureUrl', $event)"
            @removePictureUrl="onRemoveImage('ios', 'pictureUrl')"
            @pictureURLChanged="
              onPictureURLChanged('ios', 'pictureUrl', $event)
            "
          />

          <ignore-subscription
            v-if="iosEnabled"
            root-class="ignore-subscription-ct"
            :readOnly="readOnly"
            :value="config.ios.ignore_subscription"
            @input="onIgnoreSubscriptionChange($event, 'ios')"
          />

          <div
            v-if="
              iosEnabled &&
              config.ios.ignore_subscription !== 'ignore' &&
              consentPreferencesAvailable
            "
            class="ignore-subscription-ct"
          >
            <label>{{ $t('consentPreferenceType') }}:</label>
            <toggle-button
              :disabled="readOnly"
              color="#1d87c8"
              v-model="config.ios.consent_preference.enabled"
            />
            <select-consent-preference
              v-if="config.ios.consent_preference.enabled"
              v-model="config.ios.consent_preference.preference"
              :channel="ConsentChannels.PUSH"
              :preferences="consentPreferences"
              :read-only="readOnly"
            />
          </div>
        </div>

        <div class="col-md-6">
          <device-push-preview
            style="margin-left: auto; margin-right: auto"
            v-if="iosEnabled && config.ios.messages[config.defaultLang]"
          >
            <device-push-message-preview
              device="ios"
              :companyName="$store.state.app.project.name"
              :companyLogo="$store.getters.companyLogo"
              :title="config.ios.messages[config.defaultLang].title"
              :message="messageTextIOs"
              :picture="config.ios.pictureUrl"
            />
          </device-push-preview>
        </div>
      </div>
      <div class="col-md-12">
        <template-section
          v-model="config"
          :message-type="MessageTypes.PUSH_IOS"
        />
      </div>
    </tab>
    <tab
      :position="3"
      icon="android"
      id="android"
      style="padding: 20px"
      :disabled="!androidEnabled || !androidPushEnabled"
    >
      <div class="flexbox">
        <div class="col-md-6">
          <h3>{{ $t('pushToolboxLargeAndroidLabel') }}</h3>
          <device-push
            v-if="androidEnabled"
            v-model="config.android"
            :profile-attributes="profileAttributes"
            :default-lang="config.defaultLang"
            :languages="config.languages"
            :with-voucher-codes="voucherCodesEnabled('android')"
            @uploadPicture="onFileSelected('android', 'pictureUrl', $event)"
            @removePictureUrl="onRemoveImage('android', 'pictureUrl')"
            @pictureURLChanged="
              onPictureURLChanged('android', 'pictureUrl', $event)
            "
          />

          <ignore-subscription
            v-if="androidEnabled"
            root-class="ignore-subscription-ct"
            :readOnly="readOnly"
            :value="config.android.ignore_subscription"
            @input="onIgnoreSubscriptionChange($event, 'android')"
          />
          <div
            v-if="
              androidEnabled &&
              config.android.ignore_subscription !== 'ignore' &&
              consentPreferencesAvailable
            "
            class="ignore-subscription-ct"
          >
            <label>{{ $t('consentPreferenceType') }}:</label>
            <toggle-button
              :disabled="readOnly"
              color="#1d87c8"
              v-model="config.android.consent_preference.enabled"
            />
            <select-consent-preference
              v-if="config.android.consent_preference.enabled"
              v-model="config.android.consent_preference.preference"
              :channel="ConsentChannels.PUSH"
              :preferences="consentPreferences"
              :read-only="readOnly"
            />
          </div>
        </div>

        <div class="col-md-6">
          <device-push-preview
            v-if="androidEnabled && config.android.messages[config.defaultLang]"
            style="margin-left: auto; margin-right: auto"
          >
            <device-push-message-preview
              device="android"
              :companyName="$store.state.app.project.name"
              :companyLogo="$store.getters.companyLogo"
              :title="config.android.messages[config.defaultLang].title"
              :message="messageTextAndroid"
              :picture="config.android.pictureUrl"
            />
          </device-push-preview>
        </div>
      </div>
      <div class="col-md-12">
        <template-section
          v-model="config"
          :message-type="MessageTypes.PUSH_ANDROID"
        />
      </div>
    </tab>
    <tab
      :position="4"
      icon="web"
      id="web"
      style="padding: 20px"
      :disabled="!webEnabled || !webPushEnabled"
    >
      <div class="col-md-6">
        <h3>{{ $t('pushToolboxLargeWebLabel') }}</h3>
        <device-push
          v-if="webEnabled"
          v-model="config.web"
          :profile-attributes="profileAttributes"
          :default-lang="config.defaultLang"
          :languages="config.languages"
          :webPush="true"
          :with-voucher-codes="voucherCodesEnabled('web')"
          @uploadPicture="onFileSelected('web', 'pictureUrl', $event)"
          @removePictureUrl="onRemoveImage('web', 'pictureUrl')"
          @uploadIcon="onFileSelected('web', 'iconUrl', $event)"
          @removeIconUrl="onRemoveImage('web', 'iconUrl')"
          @pictureURLChanged="onPictureURLChanged('web', 'pictureUrl', $event)"
        />

        <ignore-subscription
          v-if="webEnabled"
          root-class="ignore-subscription-ct"
          :readOnly="readOnly"
          :value="config.web.ignore_subscription"
          @input="onIgnoreSubscriptionChange($event, 'web')"
        />
        <div
          v-if="
            webEnabled &&
            config.web.ignore_subscription !== 'ignore' &&
            consentPreferencesAvailable
          "
          class="ignore-subscription-ct"
        >
          <label>{{ $t('consentPreferenceType') }}:</label>
          <toggle-button
            :disabled="readOnly"
            color="#1d87c8"
            v-model="config.web.consent_preference.enabled"
          />
          <select-consent-preference
            v-if="config.web.consent_preference.enabled"
            v-model="config.web.consent_preference.preference"
            :channel="ConsentChannels.PUSH"
            :preferences="consentPreferences"
            :read-only="readOnly"
          />
        </div>
      </div>
      <div class="col-md-6">
        <web-push
          v-if="webEnabled && config.web.messages[config.defaultLang]"
          class="web-push"
        >
          <template #windows-push>
            <web-push-windows
                :title="config.web.messages[config.defaultLang].title"
                :message="messageTextWeb"
                :company-logo="$store.getters.companyLogo"
                :icon-url="config.web.iconUrl"
                :picture-url="config.web.pictureUrl"
                :url="config.web.url"
            />
          </template>

          <template #ios-push>
            <web-push-apple
                :title="config.web.messages[config.defaultLang].title"
                :message="messageTextWeb"
                :company-logo="$store.getters.companyLogo"
                :icon-url="config.web.iconUrl"
                :picture-url="config.web.pictureUrl"
                :url="config.web.url"
            />
          </template>

          <template #android-push>
            <web-push-android
                :title="config.web.messages[config.defaultLang].title"
                :message="messageTextWeb"
                :company-logo="$store.getters.companyLogo"
                :icon-url="config.web.iconUrl"
                :picture-url="config.web.pictureUrl"
                :url="config.web.url"
            />
          </template>
        </web-push>
      </div>
      <div class="col-md-12">
        <template-section
          v-model="config"
          :message-type="MessageTypes.PUSH_WEB"
        />
      </div>
    </tab>
  </tabs>
</template>
<script>
import * as Toastr from 'toastr'
import LanguageSelector from '@/components/LanguageSelector'
import MultiLanguageSelector from '@/components/MultiLanguageSelector'
import DevicePush from '@/components/DevicePush'
import DevicePushPreview from '@/components/DevicePushPreview'
import DevicePushMessagePreview from '@/components/DevicePushMessagePreview'
import WebPush from '@/components/WebPush'
import WebPushWindows from '@/components/WebPushWindows'
import WebPushApple from '@/components/WebPushApple'
import WebPushAndroid from '@/components/WebPushAndroid'
import { MessageTypes } from './MessageTypesEnum'
import MxaApi from '@/libs/MxaApi'
import Tabs from '@/components/TabSet'
import Tab from '@/components/Tab'
import SelectPromotion from '@/components/SelectPromotion'
import SelectEncryption from '@/components/SelectEncryption'
import SelectRetryType from '@/components/SelectRetryType'
import RetryUntil from '@/components/RetryUntil'
import RetryFor from '@/components/RetryFor'
import IgnoreSubscription from '@/components/IgnoreSubscription'
import NationalExclusionCheckSettings from '@/components/NationalExclusionCheckSettings.vue'
import TemplateSection from './TemplateSection.vue'
import XpApi from '@/libs/XpApi'
import SelectConsentPreference from '@/components/SelectConsentPreference'
import { ConsentChannels } from '@/enums/ConsentChannelEnum'

export default {
  props: ['action', 'readOnly', 'workflow'],

  inject: ['workflowApi'],

  components: {
    TemplateSection,
    IgnoreSubscription,
    RetryFor,
    RetryUntil,
    SelectRetryType,
    SelectEncryption,
    SelectPromotion,
    Tabs,
    Tab,
    LanguageSelector,
    MultiLanguageSelector,
    DevicePush,
    DevicePushPreview,
    DevicePushMessagePreview,
    WebPush,
    WebPushWindows,
    WebPushApple,
    WebPushAndroid,
    NationalExclusionCheckSettings,
    SelectConsentPreference
  },

  data() {
    const config = Object.assign(
      {
        defaultLang: 'en',
        languages: ['en'],
        ios: null,
        android: null,
        web: null,
        project_id: null,
        encryption: null,
        retry_type: 1,
        retry_for: 4,
        retry_for_period: 'weeks',
        retry_until: null,
        exclusion: {
          check: false,
          category: null
        }
      },
      JSON.parse(JSON.stringify(this.action.config))
    )

    return {
      promotion: {},
      selectedPromotionName: '',
      loaded: false,
      MessageTypes,
      ConsentChannels,
      config
    }
  },

  computed: {
    projectId() {
      return this.$store.state.app.project.id
    },

    iosEnabled() {
      return !!this.config.ios
    },

    androidEnabled() {
      return !!this.config.android
    },

    webEnabled() {
      return !!this.config.web
    },

    encryptionEnabled() {
      return this.$store.getters.isFeatureEnabled('pushEncryptionEnabled')
    },

    profileAttributes() {
      return this.$store.state.project.attributes
    },

    filteredLanguages() {
      return this.config.languages.filter(
        (lang) => lang !== this.config.defaultLang
      )
    },

    iosPushEnabled() {
      return !!this.$store.state.app.project.projectChannels.ios_push
    },

    androidPushEnabled() {
      return !!this.$store.state.app.project.projectChannels.android_push
    },
    webPushEnabled() {
      return !!this.$store.state.app.project.projectChannels.web_push
    },

    placeholder() {
      return this.loaded
          ? this.$t('selectOptionPlaceholder')
          : this.$t('loadingPlaceholder')
    },

    messageTextIOs() {
      const messageText = this.config.ios.messages[this.config.defaultLang].text
      if ((this.config.ios.voucher_code_enabled || 0) === 0) return messageText

      if (messageText === '') return 'X6RgzCXb'

      if (this.config.ios.voucher_code_position === 0) {
        return 'X6RgzCXb\n' + messageText
      }

      return messageText + '\nX6RgzCXb'
    },

    messageTextAndroid() {
      const messageText =
        this.config.android.messages[this.config.defaultLang].text
      if ((this.config.android.voucher_code_enabled || 0) === 0)
        return messageText

      if (messageText === '') return 'X6RgzCXb'

      if (this.config.android.voucher_code_position === 0) {
        return 'X6RgzCXb\n' + messageText
      }

      return messageText + '\nX6RgzCXb'
    },

    messageTextWeb() {
      const messageText = this.config.web.messages[this.config.defaultLang].text
      if ((this.config.web.voucher_code_enabled || 0) === 0) return messageText

      if (messageText === '') return 'X6RgzCXb'

      if (this.config.web.voucher_code_position === 0) {
        return 'X6RgzCXb\n' + messageText
      }

      return messageText + '\nX6RgzCXb'
    },

    consentPreferences() {
      return this.$store.state.project.consentPreferences
    },

    consentPreferencesAvailable() {
      return (
        this.workflow.features.consent_preferences ||
        this.$store.getters.isFeatureEnabled('consentPreferences')
      )
    }
  },

  created() {
    this.$store.dispatch('fetchAttributes')
    this.$store.dispatch('fetchTemplates', MessageTypes.PUSH_IOS)
    this.$store.dispatch('fetchTemplates', MessageTypes.PUSH_ANDROID)
    this.$store.dispatch('fetchTemplates', MessageTypes.PUSH_WEB)
    if (this.config.promotion_id) {
      const foundPromotion =
          this.$store.state.project.promotions.find((promotion) => promotion.id === this.config.promotion_id)
      if (foundPromotion === undefined) {
        XpApi.get(`projects/${this.$route.params.projectId}/promotions`, {
          params: { id: this.config.promotion_id }
        }).then(({ data }) => {
          if (data.length === 0) {
            this.updatePromotion({})
          } else {
            this.promotion = data[0]
            this.selectedPromotionName = data[0].name
          }
        })
      } else {
        this.promotion = foundPromotion
        this.selectedPromotionName = foundPromotion.name
      }
    }
    this.$store.dispatch('fetchConsentPreferences')

    if (this.encryptionEnabled && this.config.encryption == null) {
      this.config.encryption = 1
    }

    this.loaded = true
  },

  beforeDestroy() {
    // @todo jd, check for changes before performing a save
    const config = JSON.parse(JSON.stringify(this.config))
    this.workflowApi.updateAction(this.action.id, { config })
  },

  methods: {
    toggle(device) {
      if (device === 'android' && !this.androidPushEnabled) {
        return
      }

      if (device === 'ios' && !this.iosPushEnabled) {
        return
      }

      if (this.config[device]) {
        this.$set(this.config, device, null)
      } else {
        const messages = {}
        this.config.languages.forEach((lang) => {
          messages[lang] = {
            title: '',
            text: ''
          }
        })

        if (device === 'web') {
          this.$set(this.config, device, {
            messages,
            pictureUrl: '',
            iconUrl: '',
            url: '',
            ignore_subscription: 'default',
            consent_preference: {
              enabled: false,
              preference: null
            },
            voucher_code_enabled: 0,
            voucher_code_position: 1
          })

          return
        }

        this.$set(this.config, device, {
          messages,
          payload: [],
          pictureUrl: '',
          defaultAction: {
            type: 'none',
            value: ''
          },
          ignore_subscription: 'default',
          consent_preference: {
            enabled: false,
            preference: null
          },
          voucher_code_enabled: 0,
          voucher_code_position: 1
        })
      }
    },

    setDefaultLang(lang) {
      if (!this.config.languages.includes(lang)) {
        this.config.languages.push(lang)
      }
      this.config.defaultLang = lang
    },

    setLanguages(languages) {
      if (!languages.includes(this.config.defaultLang)) {
        languages.push(this.config.defaultLang)
      }
      this.config.languages = languages
    },

    onFileSelected(device, type, event) {
      const data = new FormData()
      data.append('image', event.target.files[0])
      event.target.value = null

      MxaApi.post(`projects/${this.projectId}/images`, data)
        .then(({ data }) => (this.config[device][type] = data.url))
        .catch(({ data }) => Toastr.error(data.title))
    },

    onRemoveImage(device, type) {
      this.config[device][type] = ''
    },

    onPictureURLChanged(device, type, event) {
      this.config[device][type] = event.target.value
    },

    updatePromotion(selectedPromotion) {
      this.promotion = selectedPromotion
      this.selectedPromotionName = selectedPromotion ? selectedPromotion.name : ''
      this.config.promotion_id = selectedPromotion ? selectedPromotion.id : ''
      if (this.promotion) {
        this.config.promotion_amount_required =
            this.promotion.amount_required
        this.config.promotion_duration_required =
            this.promotion.duration_required
        if (!this.promotion.amount_required) {
          this.config.promotion_amount = null
        }
        if (!this.promotion.duration_required) {
          this.config.promotion_duration = null
        }
      } else {
        this.config.promotion_amount_required = false
        this.config.promotion_duration_required = false
      }

      this.updateConfig()
    },

    updateConfig() {
      this.workflowApi.updateAction(this.action.id, { config: this.config })
    },

    onIgnoreSubscriptionChange(type, channel) {
      this.config[channel].ignore_subscription = type
    },

    voucherCodesEnabled(channel) {
      return (
        (this.workflow?.features?.voucher_codes || false) &&
        ((this.config?.[channel]?.voucher_code_enabled || 0) === 1 ||
          this.$store.getters.isFeatureEnabled('allowVoucherCodes'))
      )
    },

    querySearchPromotions(queryString, cb) {
      XpApi.get(`projects/${this.$route.params.projectId}/promotions`, {
        params: { name: queryString }
      }).then(({ data }) => {
        const list = data.map(item => {
          item.value = item.name
          return item
        })
        cb(list)
      })
    }
  },

  watch: {
    'config.retry_type': {
      immediate: true,
      handler(value) {
        switch (value) {
          case 1:
            this.config.retry_until = null
            break
          case 2:
            this.config.retry_for = null
            this.config.retry_for_period = null
            break
          case 3:
            this.config.retry_for = null
            this.config.retry_for_period = null
            this.config.retry_until = null
            break
        }
      }
    },
    'config.exclusion': {
      handler() {
        this.updateConfig()
      },
      deep: true
    }
  }
}
</script>
<style lang="sass" scoped>
.el-autocomplete
  width: 50%
  padding-top: 2px

.flexbox
    display: flex
h3
    margin-top: 0
    color: #628DBF
    font-size: 18px

.web-push
    ::v-deep .icon
        width: unset
        height: unset

.setup-tab
    padding: 20px
    .multiselect, input
        width: 50%

.ignore-subscription-ct
    padding: 0 0 20px 0

.VoucherOption
    display: flex
    flex-direction: column
    padding-right: 0
    padding-left: 0
    margin-bottom: 15px
</style>
